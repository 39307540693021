<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.administration.dedications.create'})"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'dedications'"
              :total="dataTotal"
              :fetch-path="'dedications/fetch'"
              :filter-path="'dedications/filter'"
              :export-path="'dedications/export'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'researcher.administration.dedications.edit', params: { id: field.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showDeleteModal(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type of dedication</label>
                  <v-select
                    label="name"
                    :options="types"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'dedication_type')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Percentage</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'percentage')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="0">
                      0
                    </option>
                    <option value="1 to 99">
                      1 to 99
                    </option>
                    <option value="100">
                      100
                    </option>
                  </select>
                </div>
                <p><strong>Begin date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['begin_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['begin_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_to')"
                  />
                </div>
                <p><strong>End date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['end_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['end_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_to')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Type of dedication',
          checked: true,
          order: 2,
        },
        {
          name: 'Percentage',
          checked: true,
          order: 3,
        },
        {
          name: 'Reason',
          checked: true,
          order: 4,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 5,
        },
        {
          name: 'End date',
          checked: true,
          order: 5,
        },
      ],
      sortMapping: {
        Researcher: 'user.full_name',
        'Type of dedication': 'type.name',
        Percentage: 'percentage',
        Reason: 'reason',
        'Begin date': 'begin_date',
        'End date': 'end_date',
      },
      title: 'Dedication',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'dedications/items',
      dataTotal: 'dedications/itemsTotal',
      users: 'users/fakeUsers',
      types: 'dedicationTypes/items',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'dedications')
    await this.$store.dispatch('dedications/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'dedications',
      })
    }
    await this.$store.dispatch('dedicationTypes/fetchActives')

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'dedications', url: `${Vue.prototype.$groupUrl}/dedications/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
